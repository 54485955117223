import React from 'react';
import Link from 'next/link';
import Button from '@mui/material/Button';
import Layout from 'src/components/Layout';

const styles: Record<string, React.CSSProperties> = {
  root: {
    background: '#111',
    height: '90vh',
  },
  sub: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    maxWidth: 767,
    width: '100%',
    lineHeight: 1.4,
    textAlign: 'center',
    transform: 'translate(-50%, -50%)',
  },
  notfound: {
    position: 'relative',
    height: 180,
    marginBottom: 20,
    zIndex: -1,
  },
  h1: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    fontSize: 224,
    fontWeight: 900,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: -12,
    color: '#030005',
    textTransform: 'uppercase',
    letterSpacing: -20,
    transform: 'translate(-50% , -50%)',
  },
  h2: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 110,
    fontSize: 42,
    fontWeight: 700,
    color: '#fff',
    textTransform: 'uppercase',
    textShadow: '-1px -1px 0 rgba(255, 105, 135, .3)',
    letterSpacing: '13px',
    margin: 0,
  },
  div: {
    display: 'inline-block',
    textTransform: 'uppercase',
    textDecoration: 'none',
    background: 'transparent',
    transition: '0.2s all',
  },
};

export default function Custom404(): React.ReactElement {
  return (
    <Layout>
      <div style={styles.root}>
        <div style={styles.sub}>
          <div style={styles.notfound}>
            <h1 style={styles.h1}>404</h1>
            <h2 style={styles.h2}>Page not found</h2>
          </div>
          <div style={styles.div}>
            <Link href="/" passHref legacyBehavior>
              <Button variant="contained" color="secondary" size="large">
                Home
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}
